import { env } from "md-base-tools/env";
import { postOrder } from "@/utils/apiBase.js";
import domain from '@/service/domain';
import { mapState } from "vuex";
import Error from "@@/common/Error.vue";
import lowerdev from "@/service/lowerdev";
import { getGoodsList, getAddressList } from "@/api";
import { List, showConfirmDialog, showToast } from 'vant';
import { throttle } from "@/utils/tools";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error,
    List
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      showError: false,
      showMessage: "",
      loading: true,
      miniProgram: "",
      card_status: 1,
      status: -1,
      nav: [{
        txt: '寄存中',
        status: 0
      }, {
        txt: '发货单',
        status: 1
      }],
      status_nav: [{
        txt: '全部',
        status: -1
      }, {
        txt: '待支付',
        status: 0
      }, {
        txt: '待发货',
        status: 10
      }, {
        txt: '待收货',
        status: 30
      }, {
        txt: '已完成',
        status: 50
      }, {
        txt: '已取消',
        status: 20
      }],
      page: 1,
      timer: null,
      total: 1,
      page_size: 20,
      order_list: [],
      pay_resource: 'weixin_mina',
      addressList: [],
      // 地址列表
      addressItem: {},
      // 选中地址
      openwxmina: false,
      html: '',
      iframe: false,
      is_next: false,
      iframeUrl: location.origin + "/luckycards/fastsell/openhtml",
      ifPayDown: false,
      deliverVisible: false,
      closeIcon: 'https://p6.moimg.net/path/dst_project/1063753/202211/0310/4400/22110344008lmwGOxRgXAKv73Jdjvrbd12V0p5j7.png',
      pay_id: ''
    };
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    // if (this.env.isModianIos() || this.env.isModianAndroid()) {
    //     // eslint-disable-next-line no-undef
    //     userHeader.backnavOnShow(true);
    // }
    window.userHeader.titleText("我的卡包");
    await this.getInfo();
    // 关于收货地址
    await this.getAddress();
    this.miniProgram = sessionStorage.getItem("miniProgram") == "weixin";
    const that = this;
    window.addEventListener('pageshow', that.pageshow);
    document.addEventListener("visibilitychange", function () {
      // 用户息屏、或者切到后台运行 （离开页面）
      if (document.visibilityState === "hidden") {
        console.log("hidden");
      }
      // 用户打开或回到页面
      if (document.visibilityState === "visible") {
        console.log("页面可见");
        if (that.ifPayDown) {
          mdToast('操作成功');
          setTimeout(() => {
            location.href = `${domain.wap}/luckycards/container_order_list`;
          }, 200);
        }
      }
    });
  },
  beforeUnmount() {
    const that = this;
    window.removeEventListener('pageshow', that.pageshow);
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = "1.6rem";
  },
  methods: {
    pageshow(event) {
      if (event.persisted) {
        // ios 有效, android 和 pc 每次都是 false
        location.reload();
      }
    },
    // 获取list
    async getInfo() {
      let option = {
        status: this.status == -1 ? '' : this.status,
        page: this.page,
        page_size: this.page_size
      };
      const {
        status,
        data,
        message
      } = await getGoodsList(option);
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "暂无数据";
        return;
      } else if (data && data.total == 0) {
        this.total = data.total;
        this.loading = false;
        this.showError = false;
        this.order_list.splice(0, this.order_list.length);
        this.showMessage = "暂无数据";
        return;
      } else {
        this.showError = false;
        this.total = data.total;
        if (this.page == 1) {
          this.order_list.splice(0, this.order_list.length);
        }
        this.order_list.push(...data.list);
        this.loading = false;
        this.is_next = data.is_next;
      }
    },
    onLoad() {
      if (!this.is_next) {
        this.loading = false;
        return false;
      }
      if (this.loading && this.page <= this.total) {
        this.loading = false;
        this.timer;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.page++;
          this.getInfo();
        }, 100);
      } else {
        this.loading = false;
      }
    },
    clickfn(type) {
      this.pay_resource = type;
    },
    open() {
      if (this.order_id_list.length == 0) {
        mdToast('请选择要发货的卡包');
        return false;
      }
      // 预防切换地址，保存两个字段至缓存
      sessionStorage.setItem("order_id_list", JSON.stringify(this.order_id_list));
      this.deliverVisible = true;
    },
    hideCardDetail() {
      this.cardDetailVisible = false;
      this.card_detail_list = [];
    },
    // 切换tab
    changeNav(status) {
      this.status = status;
      this.order_list.splice(0, this.order_list.length);
      this.page = 1;
      this.loading = true;
      console.log('change_nav');
      this.getInfo(1);
    },
    goContainer(item) {
      if (item.status == 0) {
        this.$router.push({
          path: "/luckycards/container",
          query: {}
        });
      }
    },
    rowScroll(e) {
      console.log(e);
    },
    getStatusTxt(code) {
      let status = '已完成';
      switch (code) {
        case 0:
          status = '待支付';
          break;
        case 10:
          status = '待发货';
          break;
        case 20:
          status = '已取消';
          break;
        case 30:
          status = '待收货';
          break;
        case 40:
          status = '待收货';
          break;
        case 50:
          status = '已完成';
          break;
        case 60:
          status = '已取消';
          break;
      }
      return status;
    },
    opOrder: throttle(async function (type, item) {
      switch (type) {
        // 取消发货单
        case 'cancel_deliver_goods':
          this.cancelOrderFun(item.postage_order_no);
          break;
        // 去支付
        case 'to_pay':
          this.reSendPay(item.postage_order_no);
          break;
        // 删除发货单
        case 'del_order':
          this.delOrderFun(item.postage_order_no);
          break;
        // 修改地址
        case 'modify_address':
          this.goToAddressList(item.postage_order_no);
          break;
        // 查看物流
        case 'query_express':
          this.$router.push({
            path: "/luckycards/logistics_list",
            query: {
              postage_order_no: item.postage_order_no
            }
          });
          break;
        default:
          mdToast('功能未开放');
      }
    }),
    async reSendPay(id) {
      let platform = '';
      if (this.env.isWeixin()) {
        platform = 'weixin';
      } else if (this.env.isWeibo()) {
        platform = 'weibo';
      } else {
        platform = 'alipay';
      }
      if (!this.env.isWeixin() && !this.env.isWeibo()) {
        if (this.pay_resource == 'weixin_mina') {
          platform = 'weixin';
        } else {
          platform = 'alipay';
        }
      }
      let channel = 'pc';
      if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
        channel = 'miniprogram';
      } else if (this.env.isWeixin()) {
        channel = 'weixin';
      } else if (this.env.isModianIos() || this.env.isModianAndroid() || this.env.isModianIosNew() || this.env.isMobile()) {
        channel = 'wap';
      } else {
        channel = 'pc';
      }
      let postParams = {
        url: "/apis/card-wallet/shipping_order/send_pay",
        method: "post",
        props: {
          postage_order_no: id,
          platform: platform,
          channel: channel
        }
      };
      if (this.env.isWeixin() || this.env.isWxApp()) {
        postOrder(postParams.url, postParams.props).then(res => {
          if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: "/pages/payPages/pay_h5/pay_h5?type=container&hasDataPay=" + encodeURIComponent(JSON.stringify(res.data.data))
            });
          } else {
            const json = {
              appId: res.data.data.appid || res.data.data.appId,
              timeStamp: res.data.data.timestamp,
              nonceStr: res.data.data.noncestr,
              package: res.data.data.package,
              signType: res.data.data.signtype,
              paySign: res.data.data.paysign
            };
            this.callWeixinPay(json);
          }
        });
      } else if (this.env.isWeibo()) {
        postOrder(postParams.url, postParams.props).then(res => {
          if (res) {
            window.location.href = res.data.data;
          } else {
            mdToast("支付失败");
          }
        });
      } else {
        this.pay_id = id;
        this.deliverVisible = true;
      }
    },
    async delOrderFun(id) {
      showConfirmDialog({
        message: "删除的发货单将不可恢复",
        confirmButtonColor: "#00C4A1"
      }).then(() => {
        var postParams = {
          url: "/apis/card-wallet/del/postage_order",
          method: "post",
          props: {
            postage_order_no: id
          }
        };
        postOrder(postParams.url, postParams.props).then(res => {
          console.log(res);
          if (res.status == 0) {
            showToast({
              type: "text",
              message: "删除成功",
              onClose: () => {
                window.location.reload();
              }
            });
          }
        });
      });
    },
    async cancelOrderFun(id) {
      showConfirmDialog({
        message: "是否确认要取消？",
        confirmButtonColor: "#00C4A1"
      }).then(() => {
        var postParams = {
          url: "/apis/card-wallet/shipping_order/cancel",
          method: "post",
          props: {
            postage_order_no: id
          }
        };
        postOrder(postParams.url, postParams.props).then(res => {
          console.log(res);
          if (res.status == 0) {
            showToast({
              type: "text",
              message: "取消成功",
              onClose: () => {
                window.location.reload();
              }
            });
          }
        });
      });
    },
    callWeixinPay(json) {
      const loadStatusData = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (WeixinJSBridge !== undefined) {
          clearInterval(loadStatusData);
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", json, res => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.log(res.err_msg);
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.PaySuccessBack();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              mdToast("取消支付");
            } else {
              mdToast(res.err_msg);
            }
          });
        }
      }, 300);
    },
    PaySuccessBack() {
      mdToast("支付成功");
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
    // 确认支付
    confirmPay() {
      let platform = '';
      if (this.env.isWeixin()) {
        platform = 'weixin';
      } else if (this.env.isWeibo()) {
        platform = 'weibo';
      } else {
        platform = 'alipay';
      }
      if (!this.env.isWeixin() && !this.env.isWeibo()) {
        if (this.pay_resource == 'weixin_mina') {
          platform = 'weixin';
        } else {
          platform = 'alipay';
        }
      }
      let channel = 'pc';
      if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
        channel = 'miniprogram';
      } else if (this.env.isWeixin()) {
        channel = 'weixin';
      } else if (this.env.isModianIos() || this.env.isModianAndroid() || this.env.isModianIosNew() || this.env.isMobile()) {
        channel = 'wap';
      } else {
        channel = 'pc';
      }
      var postParams = {
        url: "/apis/card-wallet/shipping_order/send_pay",
        method: "post",
        props: {
          postage_order_no: this.pay_id,
          platform: platform,
          channel: channel
        }
      };
      postOrder(postParams.url, postParams.props).then(res => {
        if (res.status == 0) {
          this.deliverVisible = false;
          if (this.pay_resource == 'weixin_mina') {
            window.location.href = res.data.data;
          } else if (this.env.isModianIos() || this.env.isModianAndroid()) {
            this.iframe = true;
            setTimeout(() => {
              let iframeWin = document.querySelectorAll("iframe")[0].contentWindow;
              document.querySelectorAll("iframe")[0].style.cssText += "position: fixed;display: block;border: 0px;top: 44px;left: 0px;width: 100vw;z-index: -999;height: 0;";
              iframeWin.postMessage({
                data: res.data.data,
                type: "alpay",
                dataType: res.data.dataType
              }, "*");
            }, 100);
          } else if (res.data.dataType == "URL") {
            window.location.href = res.data.data;
          } else if (res.data.dataType == "FORMDATA") {
            this.html = res.data.data;
            setTimeout(() => {
              document.forms[0].submit();
            });
          } else {
            window.location.href = res.data.data;
          }
          this.ifPayDown = true;
        } else {
          mdToast(res.message);
        }
      });
    },
    // 切换地址
    goToAddressList(postage_order_no) {
      sessionStorage.setItem('modify_address', postage_order_no);
      location.href = `${domain.wap}/address/address_list?redirectUrl=${encodeURIComponent(location.origin + location.pathname)}`;
    },
    // 获取默认地址接口
    async getAddress() {
      if (sessionStorage.getItem('address') && sessionStorage.getItem('modify_address')) {
        const res = await getAddressList({
          user_id: this.userId
        });
        if (res.status == 0 && res.data.length) {
          this.addressList = res.data;
          this.addressItem = this.addressList[0];
          let item = JSON.parse(sessionStorage.getItem('address'));
          let index = this.addressList.findIndex(ele => ele.id == item.id);
          if (index > -1 || sessionStorage.getItem('modify_address')) {
            this.addressItem = item;
            var postParams = {
              url: "/apis/card-wallet/modify/address",
              method: "post",
              props: {
                postage_order_no: sessionStorage.getItem('modify_address'),
                address: JSON.stringify(this.addressItem)
              }
            };
            sessionStorage.removeItem('modify_address');
            postOrder(postParams.url, postParams.props).then(res => {
              console.log(res);
              if (res.status == 0) {
                mdToast('地址修改成功');
                // sessionStorage.removeItem('modify_address');
                // Dialog({
                //     message: '地址修改成功',
                //     confirmButtonColor:"#00C4A1"
                // }).then(() => {
                //     window.location.reload();
                // });
              }
            });
          }
        }
      }
    },
    goOrderDetail(id) {
      this.$router.push({
        path: "/luckycards/container_order_detail",
        query: {
          postage_order_no: id
        }
      });
    }
  }
};